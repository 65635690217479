* {
    box-sizing: border-box;
  }

.plant-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 10px;
    font-family: 'Manjari', sans-serif;
    max-width: 800px;
}

.plant-form input, textarea {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid rgb(47, 233, 180);
    font-family: 'Manjari', sans-serif;
}

.add-form-buttons button {
    font-family: 'Manjari', sans-serif;
    font-size: 14px;
    width: 75px;
    height: 50px;
    background: rgb(47, 233, 180);
    border: 1px solid black;
    border-radius: 5px;
    margin: 10px;
    margin-top: 20px;
}

.plant-form button:hover {
    cursor: pointer;
}

.add-form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

input[name='plant-image'] {
    margin-bottom: 0;
}

.hosted {
    font-size: 10px;
    padding: 0;
    margin-top: -5 px;
}