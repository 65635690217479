* {
    box-sizing: border-box;
  }
  
.signup-page h2 {
    font-family: 'Bad Script', cursive;
    font-size: 25px;
    color: rgb(21, 141, 109);
    margin-top: 30px;
    font-weight: normal;
}