* {
    box-sizing: border-box
  }
  
body {
    font-size: 18px;
    color: #444;
    text-align: center;
    line-height: 1.5;
}

.search-form label {
    font-family: 'Bad Script', cursive;
    font-size: 25px;
    color: rgb(21, 141, 109);
}

.search-form {
    display: block;
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
}

.search-form input {
    height: 50px;
    width: 85%;
    border: 1px solid rgb(47, 233, 180);
    font-family: 'Manjari', sans-serif;
    font-size: 12px;
    margin-top: 10px;
    padding: 15px;
}

.search-form button {
    width: 35%;
    margin: 10px;
    font-family: 'Manjari', sans-serif;
    font-size: 14px;
    background: rgb(47, 233, 180);
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    transition-property: box-shadow;
    transition-duration: 0.25s;
    transition-timing-function: linear;
}

.search-form button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px 3px #FF7D12;
}

hr {
    width: 50%;
    color: #FFFE82;
    border: 1px solid rgb(247, 149, 70);
    border-bottom: 3px double rgb(247, 149, 70);
}

.links a {
    padding: 10px;
}

.descriptions {
    padding-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.app-description h2 {
    font-family: 'Bad Script', cursive;
    color: #353EE5;
    font-size: 22px;
}

.app-description h3 {
    font-family: 'Manjari', sans-serif;
    font-size: 18px;
    width: 75%;
    margin: auto;
    font-weight: normal;
}

.app-description p {
    font-family: 'Manjari', sans-serif;
    font-size: 16px;
    margin: 0;
}

.landing-page-container .italic {
    font-weight: normal;
    font-style: italic;
}