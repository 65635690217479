* {
    box-sizing: border-box;
  }

  .signup-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      padding: 10px;
      font-family: 'Manjari', sans-serif;
      max-width: 800px;
  }

  .signup-form input {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      padding: 5px;
      border: 1px solid rgb(47, 233, 180);
      font-family: 'Manjari', sans-serif;
  }

  .signup-form button {
    width: 75px;
      height: 50px;
      margin: 10px;
      margin-top: 20px;
      font-family: 'Manjari', sans-serif;
      font-size: 14px;
      background: rgb(47, 233, 180);
      border: 1px solid black;
      border-radius: 5px;
  }

  .signup-form button:hover {
      cursor: pointer;
  }

  .signup-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
  }

  div[role='alert'] {
      color: red;
  }