.Header {
    width: 100%;
    line-height: 40%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
}

.Header a {
    text-decoration: none;
}

.Header h1 a {
    color: rgb(23, 104, 82);
    text-shadow: 2px 1px rgb(47, 233, 180);
    font-family: 'Bad Script', cursive;
    font-size: 40px;   
}

.header-logged-in {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 30px;
    margin-right: auto;
    margin-top: 10px;
}

.header-logged-in a {
    margin-right: 10px;
    color: #353EE5;
    font-family: 'Manjari', sans-serif;
    font-size: 16px;
}

.header-logged-in a:hover{
    cursor: pointer;
    color: #FF7D12;
}

.header-logged-out {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 70px;
    margin-right: auto;
    margin-top: 5px;
}

.header-logged-out a {
    margin-right: 10px;
    color: #353EE5;
    font-family: 'Manjari', sans-serif;
    font-size: 16px;
}

.header-logged-out a:hover {
    cursor: pointer;
    color: #FF7D12;
}

.header-and-icon img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
}

.header-and-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}

@media screen and (max-width: 367px) {
    .header-and-icon h1 a {
        font-size: 30px;
    }
}