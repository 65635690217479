body {
  margin: 0;
  background: #FFFAEE;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  width: 100vw;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

main {
  min-height: 75vh;
}

footer {
  font-size: 8px;
}

.copyright {
  font-size: 10px;
}

footer a {
  text-decoration: none;
  color: #353EE5;
  font-size: 8px;
}

footer a:hover {
  cursor: pointer;
  color: #FF7D12;
}

footer a:visited {
  color: rgb(23, 104, 82);
}