* {
    box-sizing: border-box;
  }

  .unique-plant-name {
    font-family: 'Bad Script', cursive;
    font-size: 28px;
    color: rgb(21, 141, 109);
  }

  .unique-plant-details {
    margin-left: 20px;
    text-align: center; 
    font-family: 'Manjari', sans-serif;
    font-size: 18px;
    color: rgb(21, 141, 109);

  }

  .unique-plant-image img {
    height: auto;
    max-height: 50vh;
    max-width: 90%;
    border: 4px solid black;
  }

  .edit-form-buttons button, .normal-buttons button {
    height: 50px;
    background: rgb(47, 233, 180);
    color: black;
    margin: 10px;
    border: 1px solid black;
    font-family: 'Manjari', sans-serif;
    font-size: 14px;
    border-radius: 5px;
  }

  .unique-plant button:hover {
      cursor: pointer;
  }

  .edit-mode {
      max-width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
  }

  .edit-plant-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  .edit-plant-form input, textarea {
      background-color: #FFFAEE;
      border-style: inset;
      padding: 5px;
      margin: 10px;
  }

  .edit-plant-form img {
      border: 4px solid black;
      height: auto;
      max-height: 50vh;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
  }

  #plant-name {
      font-family: 'Bad Script', cursive;
      font-size: 28px;
      color: rgb(21, 141, 109);
      text-align: center;
  }

  #plant-family {
      text-align: center;
      font-family: 'Manjari', sans-serif;
      font-size: 18px;
  }

  #plant-notes {
    font-family: 'Manjari', sans-serif;
    font-size: 16px;
    height: 1.5em;
    width: 60%;
    padding: 3px;
    transition: all 0.5s ease;
  }

  #plant-notes:focus {
      height: 4em;
  }

  #plant-image {
    font-family: 'Manjari', sans-serif;
    font-size: 12px;
  }

  @media screen and (max-device-width: 375px) {
    .unique-plant-image img {
      height: auto;
      max-height: 300px;
      max-width: 100%;
    }
    .edit-plant-form img {
      max-height: 300px;
      width: 100%;
      height: auto;
      width: auto;
    }
  }
