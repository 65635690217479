* {
    box-sizing: border-box;
  }
  

.plant-container h2 {
    font-family: 'Bad Script', cursive;
    font-size: 28px;
    color: rgb(21, 141, 109);
    font-weight: normal;
}

.plant-list {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.userPlant {
  width: 48%;
  height: auto;
  margin: 2px;
  margin-bottom: 5px;
  position: relative;
}

.userPlant p {
  font-size: 12px;
  text-align: center;
  margin-right: 5%;
}

.span-name p {
  text-align: right;
  padding-right: 5px;
  font-size: 22px;
  color: white;
  font-family: 'Bad Script', cursive;
}

.name {
  position: absolute;
  width: 100%;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
}

.within-link img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
}

.hidden {
  visibility: hidden;
}